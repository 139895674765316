import Layout from '../components/layout/layout';
import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout lightNavbar={false}>
      <div className={styles.policyWrap}>
        <h1>The California Consumer Privacy Act</h1>
        <p>
          If you live in California and have an established business relationship with us, you can
          request a list of the personal information we have shared with third parties for their
          direct marketing purposes. We will also give you a list of the third parties that have
          received your information. You can make a request one time each year, by emailing or
          writing to us at the address below. Please mention in your letter that you are making a
          “California Shine the Light” inquiry. We will respond within 30 days.
        </p>
        <p>
          The California Consumer Privacy Act provides that California residents may (subject to
          certain limitations):
        </p>
        <ul>
          <li>Request the personal information we collect about them</li>
          <li>Ask that we delete the personal information we collect about them</li>
          <li>
            Opt out of having their personal information sold (while we do not share personal
            information with third parties in exchange for money, we may share information with our
            business partners from time-to-time which is broadly defined as a “sale” under the
            CCPA). Please note that there may also be instances when we share information for
            Business Purposes for providing customer service, servicing warranty repairs or claims,
            or some additional exempted reason
          </li>
        </ul>
        <p>
          <a href="mailto:info@davincimarble.com">To exercise your rights, please click here.</a>
        </p>
        <p>
          We value and respect the privacy of our customers. In the course of operating our business
          and interacting with you, we collect, use, and share personal information. We do so to
          process and fulfill your orders, to improve our product offerings, to provide valuable
          services, to protect the rights and information of our customers and our company.
        </p>
        <h2>Exercise Your Privacy Rights</h2>
        <p>
          Complete the form below to submit your request. When we receive your information, we’ll
          use it to verify your identity and review your request.
        </p>
        <p>You can:</p>
        <ul>
          <li>Request the personal information we collect about you.</li>
          <li>Ask that we delete the personal information we collect about you.</li>
          <li>
            Opt out of having your personal information sold (while we do not share your personal
            information with third parties in exchange for money, we disclose certain information in
            exchange for insights and other valuable services, and California law treats such
            sharing as a “sale” even if no money is exchanged.
          </li>
        </ul>
        <h3>IMPORTANT NOTE REGARDING REQUESTS TO OPT OUT OF SALES</h3>
        <p>
          If you submit a request to opt out of having your personal information sold, we will the
          use the information you provide to verify your identity. After verifying your identity, we
          will associate the information you provided with information we hold to prevent further
          sales of that information. However, if you use our websites, mobile applications,
          connected device applications, or digital services, we may not be able to associate the
          information you provided in your request with the information collected from your usage of
          our websites, mobile applications, connected device applications, or digital services.
        </p>
        <h3>Making a Request</h3>
        <p>
          A working email address is required to complete your request online. Call (714) 465-5220
          to speak to a representative if you don’t want to provide an email address.
        </p>
        <p>
          For each request you submit, we’ll send a verification email to the email address you
          provided. This may take up to 72 hours. Check your spam folder if you don’t see it. You’ll
          have 3 days to verify your email before your request expires. If you don’t, you’ll have to
          submit another request.
        </p>
        <p>
          If you are making a request on behalf of another person, please send your request to{' '}
          <a href="mailto:info@davincimarble.com">info@davincimarble.com</a> and include the
          following information about you and the person on whose behalf you are making the request:
          full name, mailing address, home address, email address, and phone number. You should also
          provide proof of your authorization to act on the other person’s behalf. We will contact
          you for additional information once your request has been received.
        </p>
        <p>
          After we process your request to delete your personal information or to opt out of the
          sale of your personal information, you may still see advertising regarding our products
          and services. We may deliver advertising to a general audience or place advertising on
          websites, mobile applications, and connected device applications that relates to our
          products and services. For example, if you visit a do-it-yourself website, you may see
          advertising on that website that promotes our products and services related to the
          do-it-yourself content.
        </p>
        <h3>HOW DO WE COLLECT INFORMATION?</h3>
        <p>We collect information directly from you.</p>
        <p>For example, we collect information when you:</p>
        <ul>
          <li>Visit our Design Studios or other facilities</li>
          <li>Register for an account or complete a survey</li>
          <li>Make an online or in-store purchase</li>
          <li>
            Use an online forum, provide us with comments or reviews, or contact our call centers
          </li>
          <li>
            Upload or share a photo, submit a request, submit information, or post other digital
            content through one of our websites, applications or via social media interactions on
            third party websites like Google, Facebook, Instagram, Pinterest or Twitter
          </li>
          <li>Request we send you an electronic copy of your in-store receipt</li>
          <li>Apply or inquire about employment</li>
          <li>Submit information via our websites, mobile websites, or applications</li>
        </ul>
        <p>
          We collect information from you passively.
          <br />
          We use tools like browser cookies, flash cookies, pixel tags, and web beacons to collect
          information when you use our websites and applications or interact with our emails. And we
          use cameras and other technologies in our stores, in parking lots and at other facilities
          to learn about customer traffic and for fraud prevention, security, and asset protection.
        </p>
        <p>
          We collect information from Affiliates of Select Interior Concepts.
          <br />
          We may receive information about you from companies that are controlled by or under common
          control with Select Interior Concepts (our “Affiliates”).
        </p>
        <p>
          We collect information from third parties.
          <br />
          In the course of serving you and operating our business, we collect information from a
          variety of third parties. For example, we collect information from:
        </p>
        <ul>
          <li>Shipping companies</li>
          <li>Installers</li>
          <li>Your friends and contacts</li>
          <li>Marketing companies that help us learn about consumer interests and trends</li>
          <li>Companies that supplement our customer records with additional information</li>
          <li>Online advertising and analytics providers</li>
          <li>Social media platforms</li>
          <li>Suppliers of property information</li>
          <li>Companies that help us process payments</li>
          <li>Service providers that operate on our behalf and collect information</li>
          <li>
            Financial institutions and other companies that help provide or support financial
            products and offers
          </li>
          <li>Security and fraud prevention providers</li>
          <li>Manufacturers and other businesses with which we conduct joint offers or programs</li>
        </ul>
        <h3>WHAT DO WE COLLECT?</h3>
        <p>
          CATEGORY OF PERSONAL INFORMATION IDENTIFIERS, such as name, email address, phone number,
          username, physical address, device identifier, IP address, and social media handle.
        </p>
        <p>
          WHERE DO WE COLLECT IT FROM?
          <br />
          Directly from you or the devices you use to access digital services, such as websites,
          mobile applications, and applications for connected devices.
        </p>
        <p>
          Other customers that may provide us with your information to recommend a product or
          service, ship products to you, or list you as a recipient of products or services.
        </p>
        <p>Companies that provide services on our behalf (e.g., installers).</p>
        <p>
          Security and fraud prevention services that help us confirm that transactions are valid
          and otherwise help us protect our assets and you.
        </p>
        <p>
          Marketing companies that help us learn about our customers and the devices they use to
          access digital content.
        </p>
        <p>Companies that supplement our customer records with additional information.</p>
        <p>Shipping providers that update address information.</p>
        <p>Social media platforms.</p>
        <h3>WHY DO WE COLLECT IT?</h3>
        <p>Fulfilling orders and processing returns.</p>
        <p>Customer care.</p>
        <p>Administering contests and promotions.</p>
        <p>Registering you for a website or program.</p>
        <p>Communicating with you.</p>
        <p>
          Improving our products and services, including improving store layouts and digital
          content.
        </p>
        <p>Learning about customer trends and interests.</p>
        <p>Personalizing digital content.</p>
        <p>Delivering marketing communications and advertising.</p>
        <p>Identifying the devices you use to access digital content.</p>
        <p>Fraud prevention, security, and asset protection.</p>
        <h3>WHO DO WE SHARE IT WITH?</h3>
        <p>
          Service providers that process information on our behalf, such as shipping vendors,
          providers of digital analytics services, and fraud prevention.
        </p>
        <p>Manufacturers of products and providers of extended warranty services.</p>
        <p>Any successor to all or part of our business.</p>
        <p>
          Law enforcement, public and government authorities, and other entities as we deem
          reasonably necessary to comply with law, support investigations, and protect the rights
          and property of you, us, and others.
        </p>
        <p>Our affiliates and subsidiaries.</p>
        <h3>HOW DO WE USE INFORMATION?</h3>
        <p>Here are some examples of how we use the information we collect:</p>
        <p>To respond to you and provide you with goods and services you request.</p>
        <ul>
          <li>
            Fulfilling orders, ensuring proper delivery, or providing services (such as delivering
            an electronic copy of your receipt)
          </li>
          <li>Responding to a product or service review</li>
        </ul>
        <p>To improve our business, products, and services.</p>
        <ul>
          <li>
            Improving our websites, mobile applications, and applications for other connected
            devices
          </li>
          <li>Identifying ways to make our products and services better</li>
          <li>Improving store layouts</li>
          <li>Developing new ways to purchase and browse our products and services</li>
          <li>
            Personalizing the content on our digital services (e.g., websites, mobile applications,
            and connected device applications) so you have a consistent experience
          </li>
        </ul>
        <p>To identify and assess website and device application trends and customer interests.</p>
        <ul>
          <li>Recommending products and services that may interest you</li>
          <li>
            Identifying the devices you use to interact with us so that we can provide you with a
            consistent experience
          </li>
          <li>
            Identifying your interests based on your interactions with us and information we have
            received from third parties or publicly available sources
          </li>
          <li>Updating our product inventories to meet projected demand</li>
        </ul>
        <p>For security and asset protection purposes.</p>
        <ul>
          <li>In-store, parking lot, and facility cameras track store traffic and stock</li>
          <li>Mitigating credit losses</li>
          <li>
            Detecting and preventing fraud and malicious activities on our digital offerings and in
            your accounts
          </li>
        </ul>
        <p>For our marketing.</p>
        <ul>
          <li>
            Sending you communications about special promotions or offers via regular mail, email,
            phone, or other electronic channels, including ads on social media platforms
          </li>
          <li>Sending you newsletters and other information you have requested</li>
          <li>Informing you of new in-store or digital products and service offerings</li>
          <li>
            Passing along information about offers from our Affiliates and other companies we think
            you might find interesting
          </li>
        </ul>
        <p>To communicate with you about your account or our relationship.</p>
        <ul>
          <li>
            Notifying you about changes to this Privacy and Security Statement, the Terms of Use of
            our websites or device applications, or changes to any of our programs in which you
            might be enrolled
          </li>
          <li>
            Updating you regarding shipping, product availability, product recalls, or warranty
            information
          </li>
          <li>Sending you other transactional or relationship communications</li>
        </ul>
        <p>For employment purposes.</p>
        <ul>
          <li>
            Processing and responding to your application for or inquiry regarding employment with
            us.
          </li>
        </ul>
        <p>For social media engagement.</p>
        <ul>
          <li>
            When you engage with our content on or through social networking websites, plug-ins and
            applications, you may allow us to have access to certain social media account
            information (e.g., name, username, email address, gender) as determined by the settings
            of the social media platforms to deliver the content or as part of the operation of the
            website, plug-in or application
          </li>
          <li>
            Such social media platforms may collect information about your use of our services and
            may notify other users of the platform about your activities on our websites and device
            applications
          </li>
          <li>
            These social media platforms may also use cookies or other technologies to provide
            services or track your online activities over time and across multiple websites and
            device applications. Your interactions with social media features are governed by the
            respective privacy policies of the social media platforms
          </li>
        </ul>
        <p>For other uses we may disclose to you.</p>
        <ul>
          <li>
            We may also use information for purposes disclosed to you at the point of collection, as
            authorized by you, or as otherwise permitted or required by law
          </li>
        </ul>
        <h3>TRACKING TOOLS WE USE</h3>
        <p>
          We collect personal and other information using digital tracking tools, such as cookies
          and web beacons, when you use our websites or mobile applications, interact with our
          emails or applications provided on connected devices, or otherwise engage with our digital
          offerings. We also partner with third parties that collect information this way. These
          third parties may place similar tracking tools on our websites, applications, and
          advertising to facilitate tracking, advertising, and measurement services
        </p>
        <p>
          The tools may collect information about your activities over time and on various digital
          services offered by us or others.
        </p>
        <p>The tools that we, our vendors, and third parties use include:</p>
        <ul>
          <li>
            Browser and flash cookies. Cookies are small text files that websites send to your
            computer or other connected device to identify your browser or to store information or
            settings. Your browser may provide the ability to be notified when you receive certain
            types of cookies and how to restrict or disable certain cookies. Flash cookies generally
            can be disabled only by accessing Adobe Flash settings (information available here)
          </li>
          <li>
            Server logs, which record information about the device, browser, operating system,
            network, and other resources you use to access our digital services
          </li>
          <li>
            Web beacons and pixels. These are small electronic images deployed on websites and
            emails that collect information about how users interact with content
          </li>
          <li>
            Application software. Software in our mobile and other connected-device applications
            collects information about how you interact with the digital services
          </li>
        </ul>
        <p>
          We, our service providers, and third parties use the information collected via these tools
          to:
        </p>
        <ul>
          <li>Identify new visitors and users</li>
          <li>Recognize returning customers</li>
          <li>Learn how you came to access our digital services</li>
          <li>Keep you logged into our digital services</li>
          <li>
            Personalize your experience on our website, device applications, and third-party social
            networking websites, plug-ins and applications
          </li>
          <li>Identify your location as determined by the IP address of your devices</li>
          <li>Optimize and tailor our websites and device applications</li>
          <li>
            Make product recommendations and provide you with advertising content in which we think
            you will be interested. As part of this customization, we may observe your behaviors on
            our device applications, our websites, or on other websites and applications. We may
            also get information about your browsing history and online activities from our trusted
            business partners and vendors
          </li>
          <li>
            Better understand our audience, our customers, our website visitors and device
            application users, and their respective interests, including by learning about your
            online activities across devices
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
